import { mapGetters, mapMutations } from 'vuex'
import { snakeCase } from 'lodash'

import General from '../general/index.vue'
import Seo from '../seo/index.vue'
import EducationContent from '../content/index.vue'

export default {
  name: 'education-materials-dialog-view',
  data () {
    return {
      dialog: true,
      activeTab: 'general',
    }
  },
  components: {
    General,
    Seo,
    EducationContent
  },
  computed: {
    ...mapGetters({
      currentItem: 'educationMaterials/currentItem',
	  isLoading: 'educationMaterials/isLoading',
	  updateSeoLoading: 'educationMaterials/updateSeoLoading'
    }),
    tabs () {
      return [
        {
          name: 'General',
          slug: 'general',
          disabled: false,
          id: 1
        },
        {
          name: 'Content',
          slug: 'content',
          disabled: !this.currentItem,
          id: 2
        },
        {
          name: 'SEO',
          slug: 'seo',
          disabled: !this.currentItem,
          id: 3
        }
      ]
    }
  },
  methods: {
    changeCloseDialog () {
      this.dialog = false
      this.$emit('close-dialog')
    },
    ...mapMutations({
      setContentList: 'educationMaterialsContent/SET_LIST'
    }),
    toSnakeCase (str) {
      return snakeCase(str)
    },

	save(){
	  this.$refs['general'].$emit('event-save');
	},
  },
  destroyed () {
    this.setContentList([])
  }
}
