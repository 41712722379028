import { mapActions, mapGetters, mapMutations } from 'vuex'
import { camelCase, debounce, snakeCase } from 'lodash'
import moment from 'moment'

import mixins from '@/mixins'
import validation from '@/mixins/validation'
import course from '@/mixins/course'

import MainEditor from '@/components/editor/index.vue'
import ImageFile from '@/components/image-file/index.vue'
import SlugNameInput from '@/components/slug-name-input/index.vue';

import SelectUserPosition from '@/components/select-user-position';

export default {
  name: 'education-materials-general',
  mixin: [course, mixins, validation],
  data () {
    return {
      valid: false,
      search: null,
      searchInput: null,
      payload: {
        name: '',
        slug: '',
        locale: '',
        description: '',
        preview: '',
        categoryId: '',
        countryId: '',
        statusId: '',
        tariffs: '',
        picture: '',
        cover: '',
        dateAt: '',
        sort: 1,
        authors: [],
        trial: false,
        // key for get authors
        isAuthors: this.$route.name === 'bonuses'
      }
    }
  },
  components: {
    MainEditor,
    ImageFile,
    SlugNameInput,
    SelectUserPosition
  },
  computed: {
    ...mapGetters({
      isLoading: 'educationMaterials/isLoading',
      currentItem: 'educationMaterials/currentItem',
      authors: 'user/speakers',
      setting: 'courses/setting',
      speakersLoading: 'user/speakersLoading',
      profile: 'profile/profile',
      locales: 'profile/locales',
      categories: 'educationCategories/list',
      categoriesLoading: 'educationCategories/isLoading',
      tariffs: 'educationTariffs/list',
      tariffsLoading: 'educationTariffs/isLoading',
      updateImageLoading: 'educationMaterials/updateImageLoading',
      accessCountries: 'profile/accessCountries'
    }),
    defaultCountry () {
      if (this.profile) {
        return this.profile.countries.data.find(e => e.type === 'default').country_id
      }
      return ''
    },
    // special for check slug
    getEntityWithRouteName () {
      switch (this.$route.name) {
        case 'webinars': {
          return 'webinar'
        }
        case 'qa-sessions': {
          return 'qa_session'
        }
        case 'master-classes': {
          return 'master_class'
        }
        case 'bonuses': {
          return 'bonus'
        }
      }
    }
  },
  created () {
    if (this.currentItem) {
      this.setData()
      this.fetchAuthors({
        isAuthors: this.$route.name === 'bonuses',
      }).then(() => {
        this.setData()
      })
    } else {
      this.fetchAuthors({
        isAuthors: this.$route.name === 'bonuses',
      })
    }
    if (this.$route.name !== 'bonuses') {
      if (this.categories.length <= 0) this.fetchCategories()
    }
    this.fetchTariffs({ countryIds: this.payload.countryId })

	this.$on('event-save', () =>{
	  this.submit();
	});
  },
  methods: {
    ...mapActions({
      fetchCategories: 'educationCategories/GET_LIST',
      fetchTariffs: 'educationTariffs/GET_LIST',
      update: 'educationMaterials/UPDATE',
      fetchCurrentItem: 'educationMaterials/GET_LIST_ITEM',
      create: 'educationMaterials/CREATE',
      updateImage: 'educationMaterials/UPDATE_IMAGE',
      fetchAuthors: 'user/GET_SPEAKERS'
    }),
    ...mapMutations({
      setCountryIds: 'profile/SET_COUNTRY_IDS'
    }),

	  onReloadUserPosition(){
		  this.fetchAuthors({
			  isAuthors: true,
		  })
	  },

    updateImageLocal (params) {
      params.append('material_id', this.currentItem.id)
      this.updateImage(params).then(() => {
        this.$toasted.success(this.$t('success_updated'))
      })
    },
    changeCountry (e) {
      this.setCountryIds([e])
      this.fetchTariffs({ countryIds: this.payload.countryId })
      this.payload.tariffs = ''
    },
    formattedDate (date) {
      if (!date) return ''
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    // speakers
    changeInputSpeakers: debounce(function (val) {
      if (!val) return
      if (this.search?.full_name !== val) {
        this.fetchAuthors({
          isAuthors: this.$route.name === 'bonuses',
          keywords: val,
        })
      }
    }, 500),
    autoCompleteChanged (e) {
      this.payload.authors = e.map(e => e.id)
      this.search = e
    },
    // speakers end //
    setData () {
      const transformObj = {}
      const item = this.currentItem
      Object.keys(item).forEach(e => {
        transformObj[camelCase(e)] = item[e]
      })
      Object.assign(this.payload, transformObj)
      if (this.$route.name === 'bonuses') {
        this.payload.authors = this.currentItem?.authors?.data.map(e => e.user_position_id)
      } else {
        this.payload.authors = this.currentItem?.speakers?.data.map(e => e.user_position_id)
      }
      this.setCountryIds([this.currentItem.country.data.id])
      this.payload.tariffs = this.currentItem.tariffs.data.map(e => e.id)
      this.search = this.payload.authors
    },
    createRequestPayload () {
      const data = Object.assign(this.payload)
      const formData = new FormData()
      Object.keys(data).forEach(e => {
        if (e !== 'dateAt') {
          formData.append(snakeCase(e), data[e])
        }
      })
      if (this.$route.name !== 'bonuses') {
        formData.append('speakers', this.payload.authors)
      }
      formData.append('date_at', this.formattedDate(this.payload.dateAt))
      formData.append('trial', this.payload.trial ? 1 : 0)
      if (this.currentItem) {
        formData.append('_method', 'patch')
      }
      return formData
    },
    submit () {
      this.$refs.form.validate()
      this.$refs.observer.validate()

      if (this.valid) {
        if (this.currentItem) {
          this.update(this.createRequestPayload()).then(response => {
            this.fetchCurrentItem(response).then(() => {
              this.setData()
            })
            this.$toasted.success(this.$t('success_updated'));
		  })

        } else {
		  this.create(this.createRequestPayload()).then(response => {
			this.$toasted.success(this.$t('success_created'))
			this.fetchCurrentItem(response).then(() => {
			  this.setData();
			})
		  })
		}
      }
    }
  }
}
