import { mapActions, mapGetters } from 'vuex'
import { camelCase, debounce, snakeCase } from 'lodash'

import validationMixin from '@/mixins/validation'
import mixins from '@/mixins'

import Chapters from '@/components/video-chapters/index.vue'
import ImageFile from '@/components/image-file/index.vue'
import MainEditor from '@/components/editor/index.vue'
import Draggable from 'vuedraggable'

import {
  educationMaterialsContentTableHead,
  educationMaterialsContentTypes
} from '@/modules/education/helpers/materials'

export default {
  name: 'education-materials-content',
  mixin: [mixins, validationMixin],
  data () {
    return {
      showRemoveDialog: false,
      removingItem: null,
      showContentDialog: false,
      selectedItem: null,
      valid: false,
      contents: [],
      payload: {
        typeId: '',
        name: '',
        type: '',
        sort: 1,
        link: '',
        src: '',
        active: false,
        description: ''
      }
    }
  },
  components: {
    ImageFile,
    Chapters,
    Draggable,
    MainEditor
  },
  watch: {
    'list' () {
      this.contents = this.list
    }
  },
  computed: {
    ...mapGetters({
      editId: 'educationMaterialsContent/editId',
      setting: 'courses/setting',
      currentItem: 'educationMaterials/currentItem',
      list: 'educationMaterialsContent/list',
      loading: 'educationMaterialsContent/loading',
      listLoading: 'educationMaterialsContent/listLoading',
      isLoadingChapter: 'educationChapters/isLoading'
    }),
    educationMaterialsContentTypes,
    currentType () {
      return this.educationMaterialsContentTypes.find(e => e.type === this.payload.type)
    },
    educationMaterialsContentTableHead
  },
  created () {
    if (this.list.length <= 0) {
      this.fetchList({
        parentId: this.currentItem.id
      }).then(() => {
        this.contents = this.list
      })
    } else {
      this.contents = this.list
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'educationMaterialsContent/GET_LIST',
      create: 'educationMaterialsContent/CREATE',
      remove: 'educationMaterialsContent/DELETE',
      update: 'educationMaterialsContent/UPDATE',
      updateSort: 'educationMaterialsContent/UPDATE_SORT',

      createChapter: 'educationChapters/CREATE',
      removeChapter: 'educationChapters/DELETE',
      updateChapter: 'educationChapters/UPDATE'
    }),
    updateImageLocal (params) {
      this.payload.src = params.get('src')
    },
    // remove
    removeListItem () {
      this.remove(this.removingItem).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    changeDialogDeleteItem (item) {
      this.showRemoveDialog = true
      this.removingItem = {
        parentId: this.currentItem.id,
        id: item.id
      }
    },
    // actions data
    setData (item) {
      const transformObj = {}
      Object.keys(item).forEach(e => {
        transformObj[camelCase(e)] = item[e]
      })
      Object.assign(this.payload, transformObj)
    },

    // popup change content
    resetPayload () {
      this.selectedItem = null
      Object.keys(this.payload).forEach(e => {
        this.payload[e] = ''
      })
      this.payload.sort = 1
      this.$refs.form.reset()
    },
    changeShowDialogContent (item = null, status = false, type = 'file') {
      this.selectedItem = null
      Object.keys(this.payload).forEach(e => {
        this.payload[e] = ''
      })

      this.payload.type = type
      if (item) {
        this.setData(item)
        this.payload.type = type
        this.selectedItem = item
      }

      this.showContentDialog = status
      if (!status) {
        this.resetPayload()
      }
    },
    createRequestPayload () {
      const data = this.payload
      const formData = new FormData()
      Object.keys(data).forEach(e => {
        if (e !== 'typeId' && e !== 'active') {
          formData.append(snakeCase(e), data[e])
        }
      })
      formData.append('parent_id', this.currentItem.id)
      // if added new type you need take setting.content.types
      formData.append('type_id', this.educationMaterialsContentTypes.find(e => e.type === this.payload.type).id)
      formData.append('active', this.payload.active ? 1 : 0)
      if (this.selectedItem) {
        formData.append('_method', 'patch')
      }
      return formData
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        if (!this.selectedItem) {
          this.create(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_created'))
            this.changeShowDialogContent()
          })
        } else {
          this.update(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_updated'))
            this.changeShowDialogContent()
          })
        }
      }
    },
    submitChapter (elem) {
      elem.contentId = this.selectedItem.id
      if (elem.hasOwnProperty('id')) {
        this.updateChapter(elem).then(() => {
          this.$toasted.success(this.$t('success_updated'))
        })
      } else {
        this.createChapter(elem).then(() => {
          this.$toasted.success(this.$t('success_created'))
        })
      }
    },
    removeChapterLocal (elem) {
      this.removeChapter(elem).then(() => {
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    changeSort: debounce(function (e) {
      if (e.hasOwnProperty('moved')) {
        const sortsList = this.contents.map(e => e.id)
        const formData = {
          parentId: this.currentItem.id,
          contents: sortsList
        }
        this.updateSort(formData).then(() => {
          this.$toasted.success(this.$t('success_change_position'))
        })
      }
    }, 2000)
  }
}
