import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce, snakeCase } from 'lodash'

import mixins from '@/mixins'

import DialogView from './pages/dialog-view/index.vue'
import Draggable from 'vuedraggable'
import CountryFlag from 'vue-country-flag'

import { educationMaterialsTableHead } from '@/modules/education/helpers/materials'

export default {
  name: 'education-materials',
  mixin: [mixins],
  data () {
    return {
      page: 1,
      showRemoveDialog: false,
      removingItem: null,
      openDialogView: false,
      editedItem: null,
      draggableList: []
    }
  },
  components: {
    DialogView,
    CountryFlag,
    Draggable
  },
  watch: {
    'list' () {
      this.draggableList = this.list
    },
    '$route.path' () {
      this.changeMaterialType(this.$route.name)
      this.setListContent([])
      this.setList([])
      this.init()
    }
  },
  computed: {
    ...mapGetters({
      list: 'educationMaterials/list',
      limit: 'educationMaterials/limit',
      skip: 'educationMaterials/skip',
      isListLoading: 'educationMaterials/isListLoading',
      isLoading: 'educationMaterials/isLoading',
      setting: 'courses/setting',

      accessCountries: 'profile/accessCountries',
      filterKeywords: 'educationMaterials/filterKeywords',
      filterCountries: 'educationMaterials/filterCountries',
      filterStatus: 'educationMaterials/filterStatus'
    }),
    educationMaterialsTableHead
  },
  created () {
    this.changeMaterialType(this.$route.name)
    this.init()
  },
  methods: {
    ...mapActions({
      fetchList: 'educationMaterials/GET_LIST',
      remove: 'educationMaterials/DELETE',
      updateSort: 'educationMaterials/UPDATE_SORT',
      fetchCountries: 'setting/GET_COUNTRIES'
    }),
    ...mapMutations({
      setCurrentItem: 'educationMaterials/SET_ITEM',
      setListContent: 'educationMaterialsContent/SET_LIST',
      setList: 'educationMaterials/SET_LIST',
      changeFixBody: 'system/FIX_BODY',
      changeFilter: 'educationMaterials/CHANGE_FILTER',
      changeSkip: 'educationMaterials/CHANGE_SKIP',
      changeMaterialType: 'educationMaterials/CHANGE_MATERIAL_TYPE'
    }),
    init () {
      if (!this.list.length) {
        this.fetchList().then(() => {
          this.draggableList = this.list
        })
      } else {
        this.draggableList = this.list
      }

      if (!this.countries) {
        this.fetchCountries()
      }
      if (this.list) {
        if (this.skip !== 0) {
          this.page = Math.round(this.skip / this.limit) + 1
        }
      }
    },
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchList()
    },
    changeFilterLocal (value, type) {
      const obj = {
        type: type,
        value: value
      }
      this.changeFilter(obj)
      this.changeSkip(0)
      this.sendRequest()

      this.page = 1
    },
    sendRequest: debounce(function () {
      this.fetchList()
    }, 1000),

    closeDialogView () {
      setTimeout(() => {
        this.openDialogView = false
      }, 500)
    },
    changeDialogView (item = null) {
      this.setCurrentItem(item)
      this.openDialogView = true
    },
    removeMaterial () {
      this.remove(this.removingItem).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    changeDialogDeleteItem (item) {
      this.showRemoveDialog = true
      this.removingItem = item
    },
    changeSort: debounce(function (e) {
      if (e.hasOwnProperty('moved')) {
        const sortsList = this.draggableList.map(e => e.id)
        const formData = {
          course_id: this.$route.params.course_id,
          live_streams: sortsList
        }
        this.updateSort(formData).then(() => {
          this.$toasted.success(this.$t('success_change_position'))
        })
      }
    }, 2000),
    toSnakeCase (str) {
      return snakeCase(str)
    }
  },
  destroyed () {
    this.setListContent([])
    this.setList([])
  }
}
