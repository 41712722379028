export function educationMaterialsContentTableHead () {
  return [
    {
      text: 'Name',
      value: 'name'
    },
    {
      text: 'Type',
      value: 'type'
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
  ]
}

export function educationMaterialsContentTypes () {
  return [
    {
      id: 1,
      name: 'Text',
      type: 'text'
    },
    {
      id: 2,
      name: 'File',
      type: 'file'
    },
    {
      id: 3,
      name: 'Video',
      type: 'video'
    }
  ]
}

export function educationMaterialsTableHead () {
  return [
    {
      text: 'ID',
      value: 'id'
    },
    {
      text: 'Picture',
      value: 'picture'
    },
    {
      text: 'Name',
      value: 'name'
    },
    {
      text: 'Country',
      value: 'country'
    },
    {
      text: 'Status',
      value: 'status'
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
  ]
}
